import React from "react"
import Header from "../components/Header"

export default () => {
  return(
    <div>
      <Header/>
      <div><br/><br/>404! Page not found :-( :'(</div>
    </div>
  )
}

